import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  width: 100%;
  padding-top: 138px;
  padding-bottom: 80px;

  ${MEDIA.MIN_TABLET`
    padding-top: 242px;
    padding-bottom: 100px;
  `};
`;

export const Content = styled.article`
  h1 {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 40px;

    ${MEDIA.MIN_TABLET`
      font-size: 40px;
      line-height: 56px;
      margin-bottom: 50px;
    `};
  }

  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 34px;
  }

  h3 {
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 30px;
  }

  ul {
    list-style: initial;
  }

  p,
  ul {
    font-size: 16px;
    line-height: 24px;

    ${MEDIA.MIN_TABLET`
      font-size: 18px;
      line-height: 28px;
    `};

    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }
`;
