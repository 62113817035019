import React from 'react';
import PropTypes from 'prop-types';
import { ContentWrapper } from '../../global.css';
import { Container, Content } from './textPage.css';

const TextPage = ({ content }) => {
  return (
    <Container>
      <ContentWrapper padding={30} maxWidth={835}>
        <Content dangerouslySetInnerHTML={{ __html: content }} />
      </ContentWrapper>
    </Container>
  );
};

TextPage.propTypes = {
  content: PropTypes.string.isRequired,
};

export default TextPage;
